import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import {
  useToast,
} from "@chakra-ui/react";
import { Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import {useHistory, useLocation, useParams} from "react-router-dom";
import AWSClient from "@equidefi/portals/clients/AWSClient";
import { ALL_CONTENT_TYPES } from "@equidefi/shared/constants/files";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { useVerificationReport } from "@equidefi/portals/hooks/useAccreditation";
import { APPROVED, REJECTED } from "@equidefi/shared/constants/accreditation";
import {
  capitalizeEveryWord,
  formatPhoneNumber,
} from "@equidefi/shared/helpers/string";

import AccreditationClient from "@equidefi/portals/clients/AccreditationClient";
import Spinner from "../../components/spinner";

import {
  useAccept,
  useReject,
  useVaultRecord,
} from "../../hooks/useAccreditation";
import Header from "../../layouts/header";
import "./style.css";

const Document = ({ file, number }) => {
  if (!file) return null;
  return (
    <tr>
      <td>File {number + 1}</td>
      <td className="align-right">
        <a
          href={file}
          className="btn btn-eq-primary"
          target="_blank"
          rel="noreferrer"
        >
          Download
        </a>
      </td>
    </tr>
  );
};

const InvestorDocuments = (props) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const location = useLocation();
  const [downloadURL, setDownloadUrl] = useState();
  const [spinner, showSpinner] = useState(false);
  const [reviewed, setReviewed] = useState(null);
  const [file, setFile] = useState({});
  const [rejection, setRejection] = useState(null);

  const accreditationApi = useApi(AccreditationClient);

  const { data: vaultRecord, isLoading: isLoadingVault, isError: isVaultError } = useVaultRecord(
    id,
    {
      onSuccess: async (data) => {
        const result = await accreditationApi.getDownloadUrls(data.investment_id);
        setDownloadUrl(result);
      },
      onError: (error) => console.log("token error:", error),
    }
  );

  const verificationReport = useVerificationReport(vaultRecord?.investment_id);
  const accept = useAccept(id);
  const reject = useReject(id);
  const history = useHistory();

  const resetState = () => {
    setReviewed(null);
    setFile({});
    setRejection(null);
  }

  const onDrop = useCallback(async (acceptedFiles) => {

    showSpinner(true);
    const file = acceptedFiles[0];
    const filename = file.name.split(' ').join('_');
    const extension = filename.split(".").pop().toLowerCase();

    if (!Object.keys(ALL_CONTENT_TYPES).includes(extension)) {
      toast({
        type: "error",
        description: `You cannot upload a ${extension} file type, please upload one of these supported types: ${Object.keys(
          ALL_CONTENT_TYPES
        ).join(", ")}`,
      });
      return;
    }

    const response = await verificationReport.mutateAsync({
      filename,
      extension
    });
    const client = new AWSClient();
    client
      .s3Upload(response.signedUrl, acceptedFiles[0], ALL_CONTENT_TYPES[extension], file.size)
      .then(() => {
        setFile({
          name: file.name,
          location: response.url
        })
      })
      .catch((e) => {
        console.log(e);
        toast({
          status: "error",
          description: "Your file failed to upload!",
        });
      })
      .finally(() => {
        showSpinner(false);
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    queryClient.invalidateQueries(["vault-record"]);
  }, [id, queryClient]);

  if (isLoadingVault || !downloadURL) {
    return <Spinner show={true} />;
  }

  if (isVaultError) {
    return (
      <div className="investor-documents">
        <div className="header">
          <div className="header-body">
            <h1 className="error">Permission Denied</h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Header name="Review Investor Documents" />
      <br />
      <br />
      <div className="container investor-documents">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-6 name">
                <h2>
                  {vaultRecord.user?.first_name}{" "}
                  {vaultRecord.user?.middle_name}{" "}
                  {vaultRecord.user?.last_name}{" "}
                </h2>
                <span className="entity-type">
                  ({capitalizeEveryWord(vaultRecord.user?.entity_type)})
                </span>
              </div>
              <div className="col-6 status">
                <div className="actions">
                  <button
                    type="submit"
                    className="btn w-100 btn-eq-primary"
                    disabled={vaultRecord.status === APPROVED}
                    onClick={async () => {
                      setReviewed(APPROVED);
                    }}
                  >
                    Accept
                  </button>
                  <button
                    type="submit"
                    className="btn w-100 btn-danger"
                    disabled={vaultRecord.status === REJECTED}
                    onClick={async () => {
                      setReviewed(REJECTED);
                    }}
                  >
                    Reject
                  </button>
                </div>
              </div>
            </div>
            <div className="separated" />
            <div className="row">
              <div className="col-8">
                <div>{vaultRecord.user?.address_street_1}</div>
                <div>{vaultRecord.user?.address_street_2}</div>
                <div>
                  {vaultRecord.user?.address_city},{" "}
                  {vaultRecord.user?.address_state}{" "}
                  {vaultRecord.user?.address_postal_code}
                </div>
                <div>{vaultRecord.user?.address_country}</div>
                <br />
                <div>{vaultRecord.user?.email}</div>
                <div>{formatPhoneNumber(vaultRecord.user?.phone)}</div>
              </div>
              <div className="col-4 status"></div>
            </div>
          </div>
        </div>
        {!!vaultRecord.verification_report ? (
          <div className="card">
            <div className="card-body">
              <div className="row">
                {vaultRecord.rejection_reason && vaultRecord.status === REJECTED && (
                  <div className="col-12">
                    <h4>Rejection Reason:</h4>
                    <p className="rejection">
                      {vaultRecord.rejection_reason}
                    </p>
                  </div>
                )}
                <div className="col-12">
                  <a
                    href={downloadURL.verification_report}
                    className="btn btn-eq-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Verification Report
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="title mb-4">
                  Accreditation Method:{" "}
                  <span className="bold">
                    {capitalizeEveryWord(
                      vaultRecord.accreditation_method?.replace(
                        "_",
                        " "
                      )
                    )}{" "}
                    ({capitalizeEveryWord(vaultRecord.status)})
                  </span>
                </div>
                <table className="table card-table table-hover mb-0">
                  <tbody>
                    {Array.from({ length: 5 }).map((_, i) => (
                      <Document
                        number={i}
                        key={i}
                        file={downloadURL[`doc_${i + 1}_file`]}
                        type={downloadURL[`doc_${i + 1}_type`]}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 utility-bill">
                <div className="title">Utility Bill/Address Verification</div>
                <div className="download">
                  <a
                    href={downloadURL.utility_bill}
                    className="btn btn-eq-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        size="m"
        show={!!reviewed}
        onHide={resetState}
      >
        <Modal.Header closeButton>
          <b>Please Confirm</b>
        </Modal.Header>
        <Modal.Body>
          <div className="accreditation-review">
            <div className="title">
              Are you sure you want to set this investors documents to{" "}
              <span className="bold">{reviewed?.toLowerCase()}</span>?
            </div>
            <div className="upload">
              <div className="message">
                Please upload your <b>Verification Report</b> document before
                confirming.
              </div>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <Spinner show={spinner} />
                <p>
                  { file.name ?? "Drag 'n' drop accreditation checklist file here, or click to select file" }
                </p>
              </div>
            </div>
            {reviewed === REJECTED ? (
              <div className="form-group">
                <label className="mb-2">Rejection reason</label>
                <textarea
                  rows={3}
                  name="rejection_reason"
                  className="form-control"
                  placeholder="Explain to the investor why their accreditation documents have been rejected"
                  value={rejection}
                  onChange={(value) => setRejection(value.target.value)}
                />
              </div>
            ) : null}
            <div className="buttons">
              <button
                className={`btn w-100 btn-eq-confirm ${!file.location ? "disabled" : null}`}
                disabled={!file.location || (reviewed === REJECTED && !rejection)}
                onClick={async () => {
                  if (reviewed === APPROVED) {
                    await accept.mutateAsync({ file: file.location });
                    history.push("/accreditation");
                  } else if (reviewed === REJECTED) {
                    await reject.mutateAsync({
                      file: file.location,
                      reason: rejection,
                    });
                    history.push("/accreditation");
                  }
                }}
              >
                Yes
              </button>
              <button
                className="btn w-100 btn-eq-cancel"
                onClick={resetState}
              >
                No
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvestorDocuments;
